import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import companies from "../components/companies";
import ReactMarkdown from 'react-markdown';
import Tags from "../components/tag";
import FeaturedJobs from "../components/featuredjobs";
import Location, { LocationWithHiringText } from "../components/companyLocation";
import FeaturedCompanies from "../components/featuredcompanies";
import Loading from "../components/loading";
import cms from "../components/cms/cms";
import ApplyNow from "../components/applynow";

const CompanyPage = ({ location }) => {

    const [isClient, setClient] = useState(false);

    useEffect(() => {
        setClient(true);
    }, [isClient]);

    const path = location.pathname;
    const companyId = path.split("/")[2];
    const companyObject = companies[companyId];
    const companyName = companyObject ? `${companyObject.name} ` : "";
    const isTopLevelPage = (path === "/companies" || path === "/companies/") || !companyObject;

    const Company = ({ company }) => {

        return <div className="bg-gradient-to-br from-gray-900 to-teal-800 shadow-lg overflow-hidden sm:rounded-md mt-5">
            <div className="px-4 pb-4 sm:px-6">
                <div className="flex-shrink-0 flex -ml-2 py-4 overflow-x-auto">
                    <div
                        className="inline-flex items-center flex-shrink-0 leading-5 px-2 py-1 font-semibold rounded-full bg-yellow-300 hover:bg-yellow-200 text-yellow-900 mr-2">
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></svg>
                        <span>Featured Company</span>
                    </div>
                    <div
                        className="inline-flex items-center flex-shrink-0 leading-5 px-2 py-1 font-semibold rounded-full bg-red-500 hover:bg-red-400 text-red-900 mr-2">
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg>
                        <span>Community Sponsor</span>
                    </div>
                    {
                        // TODO FIX
                    }
                    {/* <Remoteness remote={company.remote} /> */}
                </div>

                <div className="sm:flex sm:items-center sm:justify-between">
                    <div>
                        <div className="flex items-center">
                            {company.logo ? <img className="h-8 w-8 mr-2" src={company.logo} alt={company.name} /> : <></>}
                            <h1 className="text-3xl leading-5 font-bold text-red-500">
                                {company.name}
                            </h1>
                        </div>
                        <div className="mt-2 text-gray-300">
                            <div className="sm:flex items-center">
                                <span className="font-medium shrink-0">
                                    Hiring in:
                                </span>
                                {company.hiringText ?
                                    <div className="sm:flex items-center">{company.hiringText.map((location, index) => <LocationWithHiringText key={location.text} location={location} index={index} />)} </div> :
                                    <div className="sm:flex items-center">{company.locations ? company.locations.map((location, index) => <Location key={location} location={location} index={index} />) : <></>} </div>
                                }
                            </div>
                            <div className="sm:mt-0 mt-2">
                                <span className="font-medium shrink-0 sm:mb-0 mb-4 mr-2">Company size:</span>
                                <span className="mr-1">{company.size} Developers</span>
                            </div>
                            <Tags jobTags={company.tags} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="font-normal text-left px-4 sm:px-6 pt-6 sm:py-6 border-t border-gray-500">
                <ReactMarkdown className="markdown-component prose text-gray-300 min-w-full">{company.description}</ReactMarkdown>
            </div>

            <div className="flex-shrink-0 px-4 pb-4 sm:px-6">
                <ApplyNow link={company.link} category="Apply Now Companies Page" />
            </div>

        </div>
    }

    return <Layout>
        {isTopLevelPage ? <SEO
            keywords={[`${cms.keyword} Companies`]}
            title="Companies"
            description={`Looking for a ${cms.keyword} job? View all companies that are hiring on ${cms.siteUrl}. ${cms.siteUrl} is exclusively dedicated to ${cms.keyword} jobs.`}
        /> : <SEO
            keywords={[companyName]}
            title={companyName}
            description={`Looking for a ${cms.keyword} job at ${companyName}? View all ${cms.keyword} jobs at ${companyName} on ${cms.siteUrl}. ${cms.siteUrl} is exclusively dedicated to ${cms.keyword} jobs.`}
        />}
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="my-5">
                {!isClient ? <Loading size={10} /> :
                    isTopLevelPage ? <FeaturedCompanies /> : <Company company={companyObject} />
                }
            </div>

            <div className="mb-5">
                {isTopLevelPage ? <FeaturedJobs localizedTitle={`Jobs`} featuredCompanies={true} /> :
                    <FeaturedJobs localizedTitle={`Jobs`} company={companyId} />
                }
            </div>
        </div>
    </Layout>

}

export default CompanyPage;